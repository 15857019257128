.practice-section {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    flex-direction: column;
}

.practice-section {
    background-color: #ffffff;
}

.logo_div {
    position: relative;
    margin: 0;
    padding: 0;
    top: -2.5vh;
    left: -2.5vw;
}

.carousel__logo {
    width: 150px;
    position: relative;
    margin: 0;
    padding: 0;
    z-index: 10;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
}

.carousel {
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 900px;
    max-height: 550px;
    overflow: hidden;
    position: relative;
}

.carousel-item {
    visibility: visible;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    -webkit-align-items: center;
    -webkit-justify-content: flex-end;
    position: relative;
    background-color: #fff;
    flex-shrink: 0;
    -webkit-flex-shrink: 0;
    position: absolute;
    z-index: 0;
    transition: 0.6s all linear;
}

.carousel-item__info {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-flex-direction: column;
    order: 1;
    left: 0;
    margin: auto;
    padding: 0 40px;
    width: 40%;
}

.carousel-item__image {
    width: 60%;
    height: 100%;
    order: 2;
    align-self: flex-end;
    flex-basis: 60%;
    -webkit-order: 2;
    -webkit-align-self: flex-end;
    -webkit-flex-basis: 60%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    transform: translateX(100%);
    transition: 0.6s all ease-in-out;
}

.carousel-item__subtitle {
    letter-spacing: 2px;
    font-size: 10px;
    text-transform: uppercase;
    margin: 0;
    color: #7E7E7E;
    font-weight: 700;
    transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    transition: 0.4s all ease-in-out;
}

.carousel-item__title {
    margin: 15px 0 0 0;
    /* font-family: 'Playfair Display', serif; */
    /* font-size: 44px;
    line-height: 45px; */
    letter-spacing: 2px;
    /* font-weight: 700; */
    color: #2C2C2C;
    transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    transition: 0.6s all ease-in-out;
}

.carousel-item__description {
    transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    transition: 0.6s all ease-in-out;
    margin-top: 35px;
    /* font-family: 'Open Sans', sans-serif; */
    font-size: 13px;
    color: #7e7e7e;
    line-height: 22px;
    margin-bottom: 35px;
}

.carousel-item--1 .carousel-item__image {
    background-image: url('../images/carousel/realeestate.jpg');
    background-position: center;
    background-size: cover;
}

.carousel-item--2 .carousel-item__image {
    background-image: url('../images/carousel/Estate.jpg');
    background-position: center;
    background-size: cover;
}

.carousel-item--3 .carousel-item__image {
    background-image: url('../images/carousel/litigations.jpg');
    background-position: center;
    background-size: cover;
}

.carousel-item--4 .carousel-item__image {
    background-image: url('../images/carousel/Business.jpg');
    background-position: center;
    background-size: cover;
}

.carousel-item__btn {
    width: 35%;
    color: #2C2C2C;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 3px;
    font-size: 11px;
    text-transform: uppercase;
    margin: 0;
    width: 35%;
    font-weight: 700;
    text-decoration: none;
    transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    transition: 0.6s all ease-in-out;
}

.carousel__nav {
    position: absolute;
    right: 0;
    z-index: 2;
    background-color: #fff;
    bottom: 0;
}

.carousel__icon {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    fill: #5d5d5d;
}

.carousel__arrow {
    cursor: pointer;
    display: inline-block;
    padding: 11px 15px;
    position: relative;
}

.carousel__arrow:nth-child(1):after {
    content: '';
    right: -3px;
    position: absolute;
    width: 1px;
    background-color: #b0b0b0;
    height: 14px;
    top: 50%;
    margin-top: -7px;
}

.active {
    z-index: 1;
    display: flex !important;
    visibility: visible;
}

.active .carousel-item__subtitle,
.active .carousel-item__title,
.active .carousel-item__description,
.active .carousel-item__btn {
    transform: translateY(0);
    opacity: 1;
    transition: 0.6s all ease-in-out;
    visibility: visible;
}

.active .carousel-item__image {
    transition: 0.6s all ease-in-out;
    transform: translateX(0);
}