* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    // overflow-x: hidden;
    -mox-box-sizing: border-box;
}

a {
    text-decoration: none;
}

nav {
    width: 100vw;
    margin: 0 auto;
    transition: .5s;
    display: flex;
    flex-direction: row;
}

.nav_animate {
    width: 70%;
    transition: .5s;
}

nav a {
    display: inline-block;
    text-decoration: none;
    color: #505050;
    width: 25%;
    text-align: center;
    line-height: 65px;
}

.sticky {
    position: fixed !important;
    top: 0;
}

.et-hero-tabs,
.et-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    background: rgba(238, 238, 238, 0.185);
    text-align: center;
    padding: 0 2em;
    h1 {
        font-size: 2rem;
        margin: 0;
        letter-spacing: 1rem;
    }
    h3 {
        font-size: 1rem;
        letter-spacing: 0.3rem;
        opacity: 0.6;
    }
}

.et-hero-tabs-container {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 70px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background: rgb(255, 255, 255);
    z-index: 10;
    overflow: hidden;
    &--top {
        position: fixed;
        top: 0;
    }
}

.et-hero-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    color: #000;
    letter-spacing: 0.1rem;
    transition: all 0.5s ease;
    font-size: 0.8rem;
    &:hover {
        color: white;
        background: #1D3557;
        transition: all 0.5s ease;
        text-decoration: none;
    }
}

.et-hero-tab-slider {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 6px;
    background: #E63946;
    transition: left 0.3s ease;
}

@media (min-width: 800px) {
    .et-hero-tabs,
    .et-slide {
        h1 {
            font-size: 3rem;
        }
        h3 {
            font-size: 1rem;
        }
    }
    .et-hero-tab {
        font-size: 1rem;
    }
}