.bbox,
textarea,
input {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
#contact{
    margin-bottom: 10vh;
    margin-top: 10vh;
}
.contact-container {
    max-width: 510px;
    min-width: 324px;
    margin: 0 auto;
    background-color: #fff;
    border: 2px solid #cfcfcf;
    border-bottom: 3px solid #ccc;
}

.row {
    width: 100%;
    margin: 0 0 1em 0;
    padding: 0 2.5em;
}

.row.header {
    padding: 1.5em 2.5em;
    border-bottom: 1px solid #ccc;
    background: url(../images/contact-image.jpg) left -80px;
    color: #fff;
}

.contact-top {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.contact-socials {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

a.no-text-decoration {
    color: #fff;
    text-decoration: none;
}

.pull-right {
    float: right;
}

h1.contact-title {
    line-height: 1;
    border-bottom: none;
    margin: 0 0 0.1em 0;
    font-size: 1.7rem;
}

.btn {
    font-size: 1.0625em;
    display: inline-block;
    padding: 0.74em 1.5em;
    margin: 1.5em 0 0;
    color: #fff;
    border-width: 0 0 0 0;
    text-transform: uppercase;
    background-color: #1D3557;
}

.btn:hover {
    background-color: #bfbfbf;
}

.btn.btn-submit {
    background-color: #4f6fad;
    border-bottom-color: #374d78;
}

.btn.btn-submit:hover {
    background-color: #5f7db6;
}

form {
    max-width: 100%;
    display: block;
}

form ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

form ul li {
    margin: 0 0 0.25em 0;
    clear: both;
    display: inline-block;
    width: 100%;
}

form ul li:last-child {
    margin: 0;
}

form ul li p {
    margin: 0;
    padding: 0;
    float: left;
}

form ul li p.right {
    float: right;
}

form ul li .divider {
    margin: 0.5em 0 0.5em 0;
    border: 0;
    height: 1px;
    width: 100%;
    display: block;
    background-color: #4f6fad;
    background-image: linear-gradient(to right, #ee9cb4, #4f6fad);
}

form ul li .req {
    color: #ee9cb4;
}

form label {
    display: block;
    margin: 0 0 0.5em 0;
    color: #4f6fad;
    font-size: 1em;
}

form input {
    margin: 0 0 0.5em 0;
    border: 1px solid #ccc;
    padding: 6px 10px;
    color: #555;
    font-size: 1em;
}

form textarea {
    border: 1px solid #ccc;
    padding: 6px 10px;
    width: 100%;
    color: #555;
}

form small {
    color: #4f6fad;
    margin: 0 0 0 0.5em;
}

@media only screen and (max-width: 480px) {
    .pull-right {
        float: none;
    }
    input {
        width: 100%;
    }
    label {
        width: 100%;
        /* display: inline-block; */
        float: left;
        clear: both;
    }
    li,
    p {
        width: 100%;
    }
    input.btn {
        margin: 1.5em 0 0.5em;
    }
    h1 {
        font-size: 2.25em;
    }
    h3 {
        font-size: 1.125em;
    }
    li small {
        display: none;
    }
}

.thankyou-div>svg {
    width: 10%;
    color: #1D3557;
}

.hidden {
    display: none;
}

.shadow-6 {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11), 0 4px 4px rgba(0, 0, 0, 0.11), 0 8px 8px rgba(0, 0, 0, 0.11), 0 16px 16px rgba(0, 0, 0, 0.11), 0 32px 32px rgba(0, 0, 0, 0.11);
}
.thank-you-height{
    height: fit-content;
    margin-bottom: 33vh;
}