@media only screen and (min-width: 700px) {
    section {
        height: auto;
    }
    .about_cards {
        display: none;
    }
    .about_cards2 {
        display: inherit;
    }
    .practice-section {
        height: 100vh;
        flex-direction: column;
        margin-bottom: 10vh;
        margin-top: 10vh;
    }
    .about-description {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 10vh;
    }
    .mission_body {
        flex-direction: column;
        margin-bottom: 25vh;
        margin-top: 25vh;
    }
    form.front {
        width: 100%;
        margin: 0 0 1em 0;
        padding: 0 2.5em;
        padding-left: 0;
        padding-right:0;
        
    }
}

@media only screen and (max-width: 1100px) {
    .heroLogo {
        width: 40vw;
        overflow:hidden;
    }
}

@media only screen and (max-width: 1000px) {
    .heroLogo {
        width: 50vw;
        overflow:hidden;
    }
}

@media only screen and (max-width: 640px) {
    
    .about-description {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 10vh;
        width: 100%;
    }
    .heroLogo {
        width: 60vw;
        overflow:hidden;
    }
    .mobi-hide {
        display: none;
    }
    .about_cards {
        display: inherit;
    }
    .about_cards2 {
        display: none;
    }
    .aboutTitle {
        width: 100%;
    }
    .about_cards>.section {
        display: flex;
        flex-direction: column;
    }
    div.about_cards>div.section div {
        padding-left: 0;
        padding-right: 0;
    }
    .card-1 {
        order: 1;
        margin-bottom: 2.5vh;
    }
    .card-2 {
        order: 2;
        margin-bottom: 2.5vh;
    }
    .card-3 {
        order: 3;
    }
    .carousel-item {
        flex-direction: column-reverse;
    }
    .carousel-item__image {
        width: 100%;
    }
    .carousel-item__info {
        width: 100%;
    }
    .carousel__logo{
        margin: 150px;
    }
    .mission_body {
        margin-bottom: 5vh;
        margin-top: 0;
        display: block;
    }
    .blog-card {
        display: flex;
        flex-direction: column;
        margin: 0;
        box-shadow: 0 3px 7px -1px rgb(0 0 0 / 10%);
        /* margin-bottom: 1.6%; */
        background: #fff;
        line-height: 1.4;
        border-radius: 5px;
        overflow: hidden;
        z-index: 0;
    }
}

@media only screen and (max-width: 480px) {
    .tab-hide {
        display: none;
    }
}