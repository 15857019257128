:root {
    font-size: 16px !important;
    font-family: "Montserrat", sans-serif !important;
    --text-primary: #1D3557 !important;
    --text-secondary: #E63946 !important;
    --bg-primary: #ffffff !important;
    --bg-secondary: #ffe8d6 !important;
    --transition-speed: 600ms !important;
    --light: #ffffff;
    --dark: rgb(0, 0, 0);
    --title: #6b705c;
    --subtext: black;
}

::-webkit-scrollbar {
    width: 0.25rem !important;
}

::-webkit-scrollbar-track {
    background: linear-gradient( white, transparent) !important;
}

::-webkit-scrollbar-thumb {
    background: var(--text-primary) !important;
}

body {
    margin: 0 !important;
    padding: 0 !important;
}

section {
    width: 100%;
    height: 100vh;
}