.heroLogo {
    background: contain no-repeat;
    background-color: radial-gradient(rgba(0, 0, 0, 0.5), transparent);
    /* Black w/opacity/see-through */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 30vw;
    text-align: center;
    -webkit-animation: fadein 5s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 5s;
    /* Firefox < 16 */
    -ms-animation: fadein 5s;
    /* Internet Explorer */
    -o-animation: fadein 5s;
    /* Opera < 12.1 */
    animation: fadein 5s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


/* Firefox < 16 */

@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


/* Internet Explorer */

@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


/* Opera < 12.1 */

@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.heroContainer {
    background-image: url("../images/bg-main.jpg");
    background-position: center;
    background-size: cover !important;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
}