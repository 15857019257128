    $primary: #1D3557;
    $secondary: #E63946;
    $section: #ffffff;
    $muted: #999;
    $detail: #E63946;
    $title: #E63946;
    .hr-text {
        font-size: 20px;
        position: relative;
        border: 0;
        height: 1.5em;
        &:before {
            content: '';
            background: linear-gradient(to right, transparent, $detail, transparent);
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 1px;
        }
        &:after {
            content: attr(data-content);
            position: relative;
            display: inline-block;
            color: black;
            padding: 0 .5em;
            line-height: 1.5em;
            color: $title;
            background-color: rgba(0, 0, 0, 0);
        }
    }
    
    .about_body {
        padding: 5vh;
        background-color: $section;
    }
    
    .aboutTitle {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50vw;
        margin: 0 auto;
    }
    
    .title-team {
        color: $primary;
    }
    
    .aboutSubText {
        color: $muted;
    }
    
    .about-description {
        width: 66%;
        text-align: center;
        margin: 0 auto;
        // padding-left: 12.5%;
        // padding-right: 12.5%;
    }
    
    .section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
    }
    
    .columns {
        margin-top: 0;
    }
    
    .dropshadow {
        filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.5));
    }
    
    .card-1:hover {
        filter: drop-shadow(0 0 0.75rem $primary);
        transition: filter 0.4s ease-in-out;
    }
    
    .card-2:hover {
        filter: drop-shadow(0 0 0.75rem $primary);
        transition: filter .4s ease-in-out;
    }
    
    .card-3:hover {
        filter: drop-shadow(0 0 0.75rem $primary);
        transition: filter .4s ease-in-out;
    }
    
    @-webkit-keyframes card {
        0% {
            opacity: 1;
            filter: alpha(opacity=100);
            width: 320px;
            height: 1px;
            margin-top: 0px;
            border-left: 0px solid #FFF;
            background: #444a59;
        }
        60% {
            opacity: 1;
            filter: alpha(opacity=100);
            width: 320px;
            height: 1px;
            margin-top: 0px;
            border-left: 320px solid #FFF;
            background: #444a59;
        }
        61% {
            opacity: 1;
            filter: alpha(opacity=100);
            width: 320px;
            height: 1px;
            margin-top: 0px;
            border-left: 0px solid #FFF;
            background: #FFF;
        }
        100% {
            opacity: 1;
            filter: alpha(opacity=100);
            width: 320px;
            height: 420px;
            margin-top: -210px;
        }
    }
    
    @keyframes card {
        0% {
            opacity: 1;
            filter: alpha(opacity=100);
            width: 320px;
            height: 1px;
            margin-top: 0px;
            border-left: 0px solid #FFF;
            background: #444a59;
        }
        60% {
            opacity: 1;
            filter: alpha(opacity=100);
            width: 320px;
            height: 1px;
            margin-top: 0px;
            border-left: 320px solid #FFF;
            background: #444a59;
        }
        61% {
            opacity: 1;
            filter: alpha(opacity=100);
            width: 320px;
            height: 1px;
            margin-top: 0px;
            border-left: 0px solid #FFF;
            background: #FFF;
        }
        100% {
            opacity: 1;
            filter: alpha(opacity=100);
            width: 320px;
            height: 420px;
        }
    }
    
    .section {
        position: relative;
        .card {
            border-radius: 6px;
            overflow: hidden;
            max-width: 400px;
            max-height: 600px;
            margin: 0 auto;
            opacity: 1;
            filter: alpha(opacity=100);
            -webkit-animation: card 2s forwards;
            -webkit-animation-iteration-count: 1;
            -webkit-animation-delay: 0s;
            -webkit-animation: card 2s forwards;
            animation: card 2s forwards;
            -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
            -webkit-animation-delay: 0s;
            animation-delay: 0s;
            -webkit-transition-timing-function: ease-in-out;
            transition-timing-function: ease-in-out;
            border-radius: 2%;
            .header {
                height: 120px;
                // background-color: $primary;
                background-image: url("../images/Miami_Skyline.jpg");
                background-repeat: no-repeat;
                background-size: cover;
                .avatar {
                    width: 120px;
                    height: 100%;
                    position: relative;
                    margin: 0 auto;
                    img {
                        display: block;
                        width: 100%;
                        border-radius: 50%;
                        position: absolute;
                        bottom: -42px;
                        border: 4px solid rgba(255, 255, 255, 0.384);
                    }
                }
            }
            .card-body {
                padding: 30px;
                text-align: center;
                .user-meta {
                    padding-top: 20px;
                    .username {
                        font-size: 18px;
                        font-weight: 600;
                    }
                    .position {
                        font-size: 90%;
                        color: $muted;
                    }
                }
            }
            .user-bio {
                padding-top: 8px;
                font-size: 92%;
                color: inherit;
            }
        }
    }
    
    .modal-content {
        background-color: transparent;
        border: 0px;
    }
    
    .about_cards2 {
        display: none;
    }