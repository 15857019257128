.mission_body {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.res_cards {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.font-control{
    font-size: .85rem;
}

$color_white: #fff;
$color_prime: #E63946;
$color_grey: #e2e2e2;
$color_grey_dark: #a2a2a2;
.blog-card {
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    box-shadow: 0 3px 7px -1px rgba(#000, .1);
    margin-bottom: 1.6%;
    background: $color_white;
    line-height: 1.4;
    border-radius: 5px;
    overflow: hidden;
    z-index: 0;
    a {
        color: inherit;
        &:hover {
            color: $color_prime;
        }
    }
    &:hover {
        .photo {
            background-color: black;
            transition: opacity 1s ease-in-out;
            transition: transform 1s ease-in-out;
            transform: scale(1.3) rotate(3deg);
            opacity: .8;
        }
    }
    .meta {
        position: relative;
        z-index: 0;
        height: 200px;
    }
    .photo {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 110%;
        background-size: cover;
        background-position: center;
        transition: transform .2s;
    }
    .card_photo {
        width: 100%;
    }
    .description {
        padding: 1rem;
        background: $color_white;
        position: relative;
        z-index: 1;
        h1 {
            line-height: 1;
            margin: 0;
            font-size: 1.7rem;
        }
        h2 {
            font-size: 1rem;
            font-weight: 300;
            color: $color_grey_dark;
            margin-top: 5px;
        }
    }
    p {
        position: relative;
        margin: 1rem 0 0;
        &:first-of-type {
            margin-top: 1.25rem;
            &:before {
                content: "";
                position: absolute;
                height: 5px;
                background: $color_prime;
                width: 35px;
                top: -0.75rem;
                border-radius: 3px;
            }
        }
    }
    &:hover {
        .details {
            left: 0%;
        }
    }
    @media (min-width: 640px) {
        flex-direction: row;
        max-width: 700px;
        min-height: 200px;
        .meta {
            flex-basis: 40%;
            height: auto;
        }       
        .description {
            flex-basis: 60%;
            &:before {
                transform: skewX(-3deg);
                content: "";
                background: #fff;
                width: 30px;
                position: absolute;
                left: -10px;
                top: 0;
                bottom: 0;
                z-index: -1;
            }
        }
        &.alt {
            flex-direction: row-reverse;
            .description {
                &:before {
                    left: inherit;
                    right: -10px;
                    transform: skew(3deg)
                }
            }
            .details {
                padding-left: 25px;
            }
        }
    }
    @media (max-width: 639px){
        .meta {
            display: none;
        }       
    }
}